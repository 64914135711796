import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Box } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import { faFax } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles(() => ({
    drawerContainer: {
      flexGrow: 1,
      width: '250px',
      backgroundColor: '#3567b23b',
      padding: '40px 20px'
    },
    drawBox:{
        margin: '15px 0',
        border: '1px solid #1A355D',
        padding: '10px'
    },
    callBtn: {
        color: 'white',
        padding: '5px 30px',
        backgroundColor: '#3567B2',
        '&:hover': {
            backgroundColor: '#1A355D',
          }
    },
    faxBtn: {
        color: 'white',
        cursor: 'default',
        padding: '5px 30px',
        backgroundColor: '#3567B2',
        '&:hover': {
            backgroundColor: '#1A355D',
          }
    },
    title: {
        padding: '5px 0',
        textAlign: 'center'
    },
    calls:{
        margin: '10px 0'
    }
}));


const CallDrawer = () => {
    const classes = useStyles();
    const saTel = '210-332-9171';
    const saFax = '210-332-9206';
    const ausTel = '512-394-6854';

    return (
        <div className={classes.drawerContainer}>
            <Box className={classes.drawBox}>
                <Typography variant="h6" className={classes.title}> San Antonio Office </Typography>
                <Typography variant="h6" className={classes.calls}>
                    Tel: <Button variant="contained" startIcon={<CallIcon />} className={classes.callBtn} onClick={() => {window.location.href = `tel:${saTel}`}}>{saTel}</Button>
                </Typography>
                <Typography variant="h6"  className={classes.calls}>
                    Fax: <Button variant="contained" startIcon={ <FontAwesomeIcon icon={faFax} />} className={classes.faxBtn} >{saFax}</Button>
                </Typography>
            </Box>
            <Box className={classes.drawBox}>
                <Typography variant="h6" className={classes.title}> Austin Office </Typography>
                <Typography variant="h6" className={classes.calls}>
                    Tel: <Button variant="contained" startIcon={<CallIcon />} className={classes.callBtn} onClick={() => {window.location.href = `tel:${ausTel}`}}>{ausTel}</Button>
                </Typography>
                <Typography variant="h6"  className={classes.calls}>
                    Fax: <Button variant="contained" startIcon={ <FontAwesomeIcon icon={faFax} />} className={classes.faxBtn} >{saFax}</Button>
                </Typography>
            </Box>
        </div>
    )
}

export default CallDrawer;