import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Grow, TextField, Button, FormControl, Select, InputLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ContactUsSubmitAction } from './ContactUsAction';
import { fosterInterests, contactUsContent, fosterSources } from './ContactUsContent';
import { validateMailFormat } from '../../util/utilities';
import { getContactPayload } from './contactUsSelectors';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    formContainer: {
        color: '#1A355D',
    },
    desc: {
        textAlign: 'left',
        fontWeight: '800'
    },
    formInputs:{
        padding: '15px 0',
    },
    fields: {
        minWidth: 'calc(100% - 40px)'
    },
    sendBtn: {
        color: 'white',
        width: 'calc(100% - 60px)',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            backgroundColor: '#485f81',
            color: 'white',
        }
    }
  }));

const ContactUsForm = () => {
    const errObjInitialState = {
        nameErr: null, mailErr: null, validationErr: null, phErr: null
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    let mailInput = useRef(null);
    const [errs, setErrs ] = useState(errObjInitialState);
    const contactPayloadInitState = useSelector(getContactPayload);
    const [contactPayload, setContactPayload] = useState(contactPayloadInitState);

    const clearValues = () => {
        setContactPayload({
            name: '', address: '', email: '', phoneNum: 0, interest: 'Traditional Foster Care', location: 'Austin', message: '',
            sourceInfo: 'Social Media'
        });
        setErrs({
            nameErr: null, mailErr: null, validationErr: null, phErr: null
        });
        mailInput.current.value = "";
    }

    const handleTxt = (evt, txtType) => {
        switch(txtType){
            case 'name':
                setContactPayload({...contactPayload, name: evt.target.value});
                setErrs((prevState) => ({...prevState, nameErr: false}));
                break;
            case 'email':
                if(validateMailFormat(evt.target.value)){
                    setContactPayload({...contactPayload, email: evt.target.value});
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: false}));
                }
                else{
                    setContactPayload({...contactPayload, email: ''});
                    setErrs((prevState) => ({...prevState, mailErr: false, validationErr: true}));
                }
                break;  
            case 'phoneNum':
                setContactPayload({...contactPayload, phoneNum: evt.target.value});
                setErrs((prevState) => ({...prevState, phErr: false}));
                break;
            case 'location':
                setContactPayload({...contactPayload, location: evt.target.value});
                break;
            case 'message':
                setContactPayload({...contactPayload, message: evt.target.value});
                break;
            case `sourceInfo`:
                setContactPayload({...contactPayload, sourceInfo: evt.target.value});
                break;
            default: 
        }
    }

    const execScroll = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

    const handleSend = () => {
        !contactPayload.name ? setErrs((prevState) => ({...prevState, nameErr: true})) 
        : setErrs((prevState) => ({...prevState, nameErr: false}));
        !contactPayload.email ? setErrs((prevState) => ({...prevState, mailErr: true})) 
        : setErrs((prevState) => ({...prevState, mailErr: false}));
        !contactPayload.phoneNum && contactPayload.phoneNum === 0 ? setErrs((prevState) => ({...prevState, phErr: true})) 
        : setErrs((prevState) => ({...prevState, phErr: false}));
        !contactPayload.location ? setErrs((prevState) => ({...prevState, locationErr: true})) 
        : setErrs((prevState) => ({...prevState, locationErr: false}));

        if(Object.values(errs).every(item => item === false)){
            dispatch(ContactUsSubmitAction(contactPayload));
            clearValues();
        }else{
            execScroll();
        }
    }

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.formContainer}  ref={formRef}>
                    <Grid item xs={12} className={classes.formInputs}>
                        <TextField required id="name" label="Name" variant="outlined" 
                            className={classes.fields} onChange={(e) => handleTxt(e, 'name')}  error={errs.nameErr} 
                            helperText={errs.nameErr && `Enter Name`} value={contactPayload.name}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields} required id="email" label="Email" 
                        variant="outlined" type='email' onChange={(e) => handleTxt(e, 'email')}  error={errs.validationErr || errs.mailErr} 
                        helperText={errs.validationErr ? `Enter valid email address`: (errs.mailErr ? `Enter email address` : null)}
                        inputRef={mailInput}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  required id="phoneNum" label="Phone" variant="outlined"  error={errs.phErr} 
                        type='number' InputLabelProps={{ shrink: true, }} onChange={(e) => handleTxt(e, 'phoneNum')}
                        helperText={errs.phErr && `Enter your phone number`} value={contactPayload.phoneNum === 0 ? '' : contactPayload.phoneNum}/>
                    </Grid>
                  
                    <Grid item xs={12}  className={classes.formInputs}>
                        <FormControl variant="outlined" className={classes.fields} required error={errs.locationErr}>
                            <InputLabel htmlFor="native-select-location">Location</InputLabel>
                            <Select native value={contactPayload.location} 
                                onChange={(e) => handleTxt(e, 'location')} label="Location"
                                inputProps={{
                                    name: 'Location',
                                    id: 'native-select-location',
                                }}
                            >
                                {contactUsContent.map((item) => (
                                    <option value={item.title} key={item.key}>{item.title}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <FormControl variant="outlined" className={classes.fields} >
                            <InputLabel htmlFor="native-select-source">How Did you hear about us</InputLabel>
                            <Select native value={contactPayload.sourceInfo} 
                                onChange={(e) => handleTxt(e, 'sourceInfo')} label="How Did you hear about us"
                                inputProps={{
                                    name: 'source',
                                    id: 'native-select-source',
                                }}
                            >
                                {fosterSources.map((item) => (
                                    <option value={item.value} key={item.value}>{item.value}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <TextField className={classes.fields}  id="message" label="Message" minRows={5} variant="outlined" 
                        multiline onChange={(e) => handleTxt(e, 'message')} value={contactPayload.message}/>
                    </Grid>
                    <Grid item xs={12}  className={classes.formInputs}>
                        <Button className={classes.sendBtn} size="medium" color="inherit" 
                            onClick={handleSend}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Grow>
        </div>
    )
}

export default ContactUsForm;
