import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, Slide, Grow, List, ListItem, ListItemText, Tabs, Tab, Card, CardActionArea, CardContent, Divider } from '@material-ui/core';
import { servicesContent, tab1Content, tab2Content, tab3Content, tab4Content, tab5Content } from './floorplanContent';
import ImageSlider from '../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";
import TwoBedRoomplan from "../../assets/img/gallery/2bedroom_01.png";
import ThreeBedRoomplan from "../../assets/img/gallery/3bedroom_01.png";
import IndependentHomePlan from "../../assets/img/gallery/independenthomeplan_01.png";
import TwoBedRoomplanPdf from "../../assets/img/gallery/2bedroom.pdf";   
import ThreeBedRoomplanPdf from "../../assets/img/gallery/3bedroom.pdf"; 
import IndependentHomePlanPdf from "../../assets/img/gallery/independenthomeplan.pdf"; 
//import { SingleImage} from 'react-magnific-popup';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`services-tabpanel-${index}`} 
            aria-labelledby={`services-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}>
                     {children}
                </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    svcTitle:{
        padding: '0 16px'
    },
    svcContainer: {
        color: '#1A355D',
        padding: '10px 0',
      },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 4px 11px 1px rgb(90 78 80 / 55%)',
        backgroundColor: 'white',
    },
    panelContainer: {
        color: '#1A355D',
    },
    card: {
        padding: '20px',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardHead: {
        color: '#22477E',
        padding: '7px 0'
    },
    imgSubStyles: {
      height: 'auto',
      width:'100%',
    }
  }));

const a11yProps = (index) => {
    return {
        id: `services-tab-${index}`,
        'aria-controls': `services-tabpanel-${index}`,
    };
}

const SingleImageConfig_1 = {
	type: 'image',
	closeOnContentClick: true,
	mainClass: 'mfp-img-mobile',
	image: {
		verticalFit: true
	}	
};

const ServicesView = () => {
    const classes = useStyles();
    // const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
   
    return (
        <div className={classes.root}>
          
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="Building Marketing tabs"
                className={classes.tabs}
            >
                 {servicesContent.serviceTabs.map((tab, index) => (
                 <Tab label={tab.label} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                        <a href = {TwoBedRoomplanPdf} target = "_blank">
                            <img  src={TwoBedRoomplan} className={classes.imgSubStyles} alt="1" />
                        </a>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                        <a href = {ThreeBedRoomplanPdf} target = "_blank">
                            <img  src={ThreeBedRoomplan} className={classes.imgSubStyles} alt="1" />
                        </a>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabsPanel}>
                <Grid container className={classes.panelContainer}>
                    <Grid item>
                        <Grow in={true} timeout={1500}>
                        <a href = {IndependentHomePlanPdf} target = "_blank">
                            <img  src={IndependentHomePlan} className={classes.imgSubStyles} alt="1" />
                        </a>
                        </Grow>
                    </Grid>
                </Grid>
            </TabPanel>
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    );
}

export default ServicesView;