export const EMAIL_TYPE = {
	contact: 'CONTACT_US',
    reqInfo: 'REQ_INFO',
	apply: 'APPLY_NOW',
	jobApply: 'JOB_APPLICATION'
}

/*
* This will be used in 2 places. 
* 1. Home Page --> Get Involved (Read More) --> Request Information  (this flow will not have address and city, 
     subject should be populated in interest property)
* 2. Contact US 
data object: 
{
    name:
    email:
    phoneNum: 
    address:
    location:
    interest:
    message:
    sourceInfo:
}
*/

/*
Apply Now
data object: 
{
    adults: [{
        firstName:
        lastName:
        middleName:
        homePhone:
        cellPhone:
    }],
    street:
    city:
    state:
    zip:
    country:
    areasOfInterest: {,}
    aboutUs:
    ageCheck:
    hasSteadyIncome:
    comfortCheck:
    assistMessage:
    relationshipMessage:
    experienceMessage:
}
*/

/*
JOB Application (file type and file blob are optional )
data object: 
{
    name:
    email:
    phone:
	fileType: 
	fileBlob:
}
*/