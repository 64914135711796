import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Button, Grid, Slide, Grow, List, ListItem, ListItemIcon, ListItemText,
   Card, CardActionArea, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { useHistory } from 'react-router';
import { homeContent } from './HomeContent';
import AlbumIcon from '@material-ui/icons/Album';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ImageSlider from '../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";
import GoogleMapView from '../GoogleMap/GoogleMapView';
import ServicesView from '../floorplan/floorplanView';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '5px 0',
    },
    homeContainer: {
      color: '#1A355D',
      padding: '30px',
      boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)'
    },
    homeTitle: {
      fontWeight: '500'
    },
    desc: {
      padding: '25px'
    },
    fosterChildImg:{
      boxShadow: '9px -4px 7px 2px rgb(90 78 80 / 55%)'
    },
    cardsItems:{
      padding: '20px'
    },
    card: {
      padding: '20px',
      '& .MuiButtonBase-root': {
        cursor: 'default',
        backgroundColor: '#3567b20f'
      }
    },
    cardTitle: {
      color: '#d04e53'
    },
    media:{
      padding: '15px',
      color: '#22477E'
    },
    readMore: {
      justifyContent:'center',
      padding: '10px 0',
      '& .MuiButtonBase-root': {
        color: 'white',
        width: '100%',
        cursor: 'pointer',
        backgroundColor: '#22477E',
        '&:hover': {
            color: 'white',
            backgroundColor: '#485f81',
        }
      }
    },
    listIcon:{
      color: '#22477E',
      minWidth: '25px'
    },
    listLinks: {
      padding: 0,
    },
    secList: {
      padding: '0 20px'
    },
    secListRedirects:{
      textDecoration: 'none',
      color: '#22477E',
    }
  }));

const HomeView = () => {
    const classes = useStyles();
    // const dispatch = useDispatch();
    const history = useHistory();
    
    const handleReadMoreRedirect = (redirectRoute) => {
      history.push(redirectRoute);
    }

    return (
      <Box className={classes.root} >
        {/* carousel */}
        <ImageSlider />
        <GoogleMapView/>
        <ServicesView/>
        
        <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
      </Box>
    );
}
export default HomeView;