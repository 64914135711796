import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Grid, Fab, SwipeableDrawer, Tooltip } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import CallIcon from '@material-ui/icons/Call';
import CallDrawer from './CallDrawer';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      position: 'relative',
      bottom: 0,
      background: 'linear-gradient(132deg, #22477E 30%, #3567B2 90%)',
      boxShadow: '1px -4px 5px 2px rgb(90 78 80 / 42%)',
      color: 'white',
    },
    grid:{
      padding: '24px',
      minHeight: '80px'
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
    fab1:{
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
      fab2:{
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(11),
    },
      fab3:{
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(19),
    },
      fabSocial:{
        color: 'white',
        backgroundColor: '#22477E',
        '&:hover': {
            backgroundColor: '#1A355D',
        },
        border: '1px #1A355D solid'
    },
   
  }));

const Footer = () => {
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleContactDrawer = (event, flag) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setIsDrawerOpen(flag);
    }

    return (
        <div className={classes.root}>
            <SwipeableDrawer
                anchor="right"
                open={isDrawerOpen}
                transitionDuration={{
                enter: 500,
                exit: 500
                }}
                onClose={(e) => toggleContactDrawer(e, false)}
                onOpen={(e) => toggleContactDrawer(e, true)}
            >
                <CallDrawer />
            </SwipeableDrawer>    
            <Grid container alignItems="center" className={classes.grid}>
                <Grid item xs={4} sm={4} md={6} xl={6}>
                    <Typography variant="subtitle1" className={classes.title}>
                        ©Roost Condos.
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;