import { faHandsHelping, faChild, faHandHoldingHeart, faCaretRight, faHandHoldingUsd, 
    faHands, faInfoCircle, faQuestionCircle, faExternalLinkAlt, faHandPointRight } from '@fortawesome/free-solid-svg-icons';

export const homeContent = {
    mainHead: {
        key: 'mainHead',
        value: 'Why do we need foster care in Texas?'
    },
    mainContent: [
        {
            key: 'mainList1',
            value: `We need foster care due to the ever increasing number of abused and neglected children needing homes.`
        },
        {
            key: 'mainList2',
            value: `There are over 10,000 Texas children waiting for foster and/or adoptive homes.`
        },
        {
            key: 'mainList3',
            value: `In 2021 in Bexar County, there were 17,000 investigations of reported child abuse, 
            9000 of these were for neglect. Last year half of the fatalities of children were linked to neglect.`
        },
        {
            key: 'mainList4',
            value: `When foster homes are not found, these children are having to stay at Child Protective Services (CPS) offices on cots,
             and/or siblings are being split up and placed in separate foster homes or in residential treatment centers (RTC's).`
        },
        {
            key: 'mainList5',
            value: `In Bexar County and the surrounding counties, there were 1188 children adopted last year.`
        },
    ],
    mainReadCards: [
        {
            key: 'mainRead1',
            title: 'Texas Foster Care Services',
            cardIcon: faHandsHelping,
            readMoreLink: 'services',
            cardContentList: [
                {
                    key:'read1List1',
                    value: `We work hard to support the foster children and parents, giving the parents the tools required to give our foster 
                    children their best chance at success in regaining their normal lives.`,
                    icon: faCaretRight
                },
                {
                    key:'read1List2',
                    value: `We serve the Central Texas area, including San Antonio, Austin and surrounding communities.`,
                    icon: faCaretRight
                }
            ]
        },
        {
            key: 'mainRead2',
            title: 'Becoming a Foster Parent',
            cardIcon: faChild,
            readMoreLink: 'becomeFosterParent',
            cardContentList: [
                {
                    key:'read2List1',
                    value: `Becoming a foster parent is both rewarding and challenging. In most cases, the primary goal of foster care is 
                    for the child and biological parents to be reunified.`,
                    icon: faCaretRight
                },
                {
                    key:'read2List2',
                    value: `The following is a brief overview of what is expected of Foster Parents:`,
                    icon: faCaretRight
                },
            ],
            cardContentSecList: [
                {
                    key:'read2SecList1',
                    value: `Protecting and Nurturing Children`,
                    isRedirect: false,
                    icon: faHandPointRight
                },
                {
                    key:'read2SecList2',
                    value: `Meeting Children's Developmental Needs and Addressing Developing Delays`,
                    isRedirect: false,
                    icon: faHandPointRight
                },
                {
                    key:'read2SecList3',
                    value: `Supporting Relationships Between Children and Families`,
                    isRedirect: false,
                    icon: faHandPointRight
                },
                {
                    key:'read2SecList4',
                    value: `Connecting Children to Safe,Nurturing Relationships Intended to Last a Lifetime`,
                    isRedirect: false,
                    icon: faHandPointRight
                },
                {
                    key:'read2SecList5',
                    value: `Working as a Member of a Professional Team`,
                    isRedirect: false,
                    icon: faHandPointRight
                },
            ]
        },
        {
            key: 'mainRead3',
            title: 'Get Involved',
            cardIcon: faHandHoldingHeart,
            readMoreLink: 'join',
            cardContentList: [
                {
                    key:'read3List1',
                    value: `Building Families. Changing Lives. One Child at a Time.`,
                    icon: faCaretRight
                }
            ],
            cardContentSecList: [
                {
                    key:'read3SecList1',
                    value: `Donate`,
                    isRedirect: true,
                    redirectLink: 'donation',
                    icon: faHandHoldingUsd
                },
                {
                    key:'read3SecList2',
                    value: `Volunteer`,
                    isRedirect: true,
                    redirectLink: 'volunteer',
                    icon: faHands
                },
                {
                    key:'read3SecList3',
                    value: `Articles And Information`,
                    isRedirect: true,
                    redirectLink: 'articlesInfo',
                    icon: faInfoCircle
                },
                {
                    key:'read3SecList4',
                    value: `Frequently Asked Questions`,
                    isRedirect: true,
                    redirectLink: 'faq',
                    icon: faQuestionCircle
                },
                {
                    key:'read3SecList5',
                    value: `Helpful Links`,
                    isRedirect: true,
                    redirectLink: 'helpLinks',
                    icon: faExternalLinkAlt
                },
            ]
        }
    ]
}