import NotificationManager from 'react-notifications/lib/NotificationManager';

import { contactUs } from '../../util/fosterCareApi';
import { logger } from '../../logger';

export const CONTACT_US_SUBMIT = "CONTACT_US_SUBMIT";
export const CONTACT_US_SUBMIT_SUCCESS = "CONTACT_US_SUBMIT_SUCCESS";
export const CONTACT_US_SUBMIT_FAILURE = "CONTACT_US_SUBMIT_FAILURE";

export const ContactUsSubmitAction = (contactPayload) => {
    return (dispatch) => {
        dispatch({ type: CONTACT_US_SUBMIT, payload: contactPayload});
        contactUs(contactPayload).then( response => {
            (response && response.data && response.data.emailSent) &&
                dispatch({type: CONTACT_US_SUBMIT_SUCCESS, payload: response.data});
                NotificationManager.success('Email sent successfully!');
        }).catch( err => {
            let logOptions = {
                level: 'error',
                meta: contactPayload
            };
            if(err.response && err.response.data && !err.response.data.isValid && err.response.data.errors){
                dispatch({type: CONTACT_US_SUBMIT_FAILURE, payload: err.response.data.errors});
                NotificationManager.error(Object.values(err.response.data.errors));
            }else if(err.response && err.response.data && !err.response.data.emailSent && err.response.data.errors){
                logger.error('unable to send email due to user validation issues for contact us action for the payload: ', logOptions);
                dispatch({type: CONTACT_US_SUBMIT_FAILURE, payload: err.response.data.errors});
                NotificationManager.error(err.response.data.errors.form);
            }else {
                logger.error('unable to send email for contact us action for the payload: ', logOptions);
                dispatch({type: CONTACT_US_SUBMIT_FAILURE, payload: err.response});
                NotificationManager.error(err.message);
            }
        })
    }
}
