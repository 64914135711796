import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Grow, Tabs, Tab,  Box,  Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import { eventsContent } from './EventsContent';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ImageSlider from '../../layout/ImageSlider';
import ScrollUpButton from "react-scroll-up-button";
import GoogleMapView from './GoogleMapView';
import GoogleMapsContainer from './GoogleMapsContainer';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    
    
        return (
            <div role="tabpanel" hidden={value !== index} id={`events-tabpanel-${index}`} 
            aria-labelledby={`events-tab-${index}`} {...other} >
            {value === index && (
                <Box p={3}> {children} </Box>
            )}
            </div>
        );
    }

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    tabs: {
        backgroundColor: '#3567b23b',
        '& .MuiTab-textColorInherit.Mui-selected': {
            color: '#d04e53',
            fontWeight: '900'
          },
        '& .PrivateTabIndicator-colorSecondary-18': {
            backgroundColor: '#d04e53'
        },
        '& .PrivateTabIndicator-vertical-19': {
            width: '5px'
        }
      },
    tabsPanel: {
        boxShadow: '0px 4px 11px 1px rgb(90 78 80 / 55%)',
        backgroundColor: 'white',
    },
    desc: {
        fontWeight: '800',
        padding: `20px 0`
    },
    subTxt: {
        textAlign: 'left'
    },
    card: {
        width: '100%',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardsItems:{
        padding: '20px'
      },
    media:{
        padding: '17px',
        color: '#22477E',
        height:'360px'
      },
    googlemap: {
        height: 'auto',
        width:'100%',
      }
  }));

const a11yProps = (index) => {
    return {
        id: `events-tab-${index}`,
        'aria-controls': `events-tabpanel-${index}`,
    };
}

const EventsView = () => {
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Grow in={true} timeout={1500}>
                <Grid container className={classes.body}>
                    <Grid item xs={12} className={classes.title}>
                        <Typography variant="h4" >
                            Location
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
            <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="Location"
                className={classes.tabs}
            >
                 {eventsContent.locations.map((tab, index) => (
                 <Tab label={tab.title} {...a11yProps(index)} key={tab.key}/>
            ))}
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabsPanel}>
                <Grow in={true} timeout={1500}>
                    <Grid container className={classes.panelContainer}>
                        <Card className={classes.card}> 
                            <CardActionArea> 
                                <CardMedia className={classes.media}  >
                                   <GoogleMapsContainer/>
                                </CardMedia>          
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grow>
            </TabPanel>
            <ScrollUpButton style={{ backgroundColor: 'rgb(34 71 126 / 72%)', bottom: '100px'}}/>
        </div>
    )
}

export default EventsView;