import {CONTACT_US_SUBMIT, CONTACT_US_SUBMIT_SUCCESS, CONTACT_US_SUBMIT_FAILURE} from './ContactUsAction';

const defaultState ={
    contactPayload: {
        name: '',
        address: '',
        email: '',
        phoneNum: 0,
        interest: 'Traditional Foster Care',
        location: 'San Antonio',
        message: '',
        sourceInfo: 'Social Media'
    },
    isSending: false
}

const ContactUsReducer = (state = defaultState, action) => {
    switch(action.type){
        case CONTACT_US_SUBMIT: 
            return {...state, contactPayload: action.payload, isSending: true};
        case CONTACT_US_SUBMIT_SUCCESS:
        case CONTACT_US_SUBMIT_FAILURE:
            return {state: defaultState, isSending: false}
        default : 
            return state;
    }
}
export default ContactUsReducer;