import React from 'react';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
//import { typography } from 'material-ui/styles';
class GoogleMapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: true,
      activeMarker: {},
      selectedPlace: {}
    }
    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  onMapClick = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: true,
        activeMarker: null
      });
    }
  }
  render() {
    const style = {
      
      height: '360px',  
      
    }
    return (
       
        <Map
        item
        xs = { 2 }
        style = { style }
        google = { this.props.google }
        onClick = { this.onMapClick }
        zoom = { 14 }
        initialCenter = {{ lat: 30.4083752, lng: -97.6925999 }}
      >
        <Marker
          onClick = { this.onMarkerClick }
          title = { 'Changing Colors Garage' }
          position = {{ lat: 30.4083752, lng: -97.6925999 }}
          name = { 'Changing Colors Garage' }
        />
        <InfoWindow
          marker = { this.state.activeMarker }
          visible = { true }
        >
          <Paper>
            <Typography
              variant = 'headline'
              component = 'h4'
            >
              Roost Condos
            </Typography>
            <Typography
              component = 'p'
            >
              1704 Cedar Bend Drive, Austin,<br />
              TX 78132
             
            </Typography>
          </Paper>
        </InfoWindow>
      </Map>
      

    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBdThth3gHkO3QZ1TXbCh5dlrfshOfvx2E"
})(GoogleMapsContainer)