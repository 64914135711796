import { faMapMarkedAlt, faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons';

export const contactUsContent = [
    {
        key: `austinContact`,
        title: `Austin`,  
        contact: [
            {
                key: `austinAddress`,
                title: `Address`,
                icon: faMapMarkedAlt,
                line1: `12593 Research Blvd, #302`,
                line2: `Austin, TX 7875`
            }
        ]
    }
]

export const fosterInterests = [
    {
        value: `Traditional Foster Care`
    },
    {
        value: `Foster to Adopt`
    },
    {
        value: `Volunteer`
    },
    {
        value: `Respite Provider`
    },
    {
        value: `Therapeutic Foster Care`
    },
    {
        value: `Charitable Contribution`
    },
    {
        value: `Other`
    }
]

export const fosterSources = [
    {
        value: `Social Media`
    },
    {
        value: `From a friend`
    },
    {
        value: `News Paper`
    }
]