import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ContactUsReducer from '../components/contactUs/ContactUsReducer';


const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    contactUsInfo: ContactUsReducer
});

export default rootReducer;