export const servicesContent = {
    serviceTabs: [
        {
            key: 'BuildinMarkingTab1',
            label: '2 Bedroom Floor Plan'
        },
        {
            key: 'BuildinMarkingTab2',
            label: '3 Bedroom Floor Plan'
        },
        {
            key: 'BuildinMarkingTab3',
            label: 'Independent Home Plan'
        }
    ],
    svcContent: [
        {
            key: 'svcAbstract1',
            value: `Texas Foster Care and Adoption Services 
            is a state-licensed, private non-profit agency dedicated to finding homes for 
            children who desperately need them.`
        },
        {
            key: 'svcAbstract2',
            value: `We continuously search for people in the community to provide loving, nurturing and 
            healing homes to abused and neglected children who have been identified by the Texas Department of Family and 
            Protective Services.`
        },
        {
            key: 'svcAbstract3',
            value: `Here are some of the services we offer:`
        }
    ]
}

export const tab1Content = [
    {
        key: 't1p1',
        value: `Foster Care is when a child is placed in the temporary care of a 
        family other than his/her own as the result of problems or challenges that are 
        taking place within the birth family, or while critical elements of an adoption are being completed`
    },
    {
        key: 't1p2',
        value: `Foster parents are primarily ordinary people who have decided to step-up to 
        make a difference in the life of a child, but they are often not alone in their work. Most 
        foster agencies offer support to help foster parents be successful. Often the foster parents 
        make up a small part of the overall foster care team, which can include the child, foster-adopt 
        parents, mental health providers, educators, child advocates, medical providers, case workers, 
        the child's biological parents, and the court. The goal of this team is to ensure the needs of the child 
        are being met, and to find the child a permanent home, including reunification with the biological parents.`
    },
    {
        key: 't1p3',
        value: `There are several different types of foster care, 
        including traditional care, emergency foster care and respite foster care.`
    },
    {
        key: 't1p4',
        value: `In the traditional foster care model, a small number of children can stay with a single foster 
        parent or family. Usually this occurs in the foster parents' home and can last for a few months or even years. 
        If necessary, this care may even be only a few days or months, depending on the reunification plan with their 
        biological parents.`
    },
    {
        key: 't1p5',
        value: `If a social worker or system cannot figure out a longer term solution for a foster child, 
        an emergency foster care home is available 24 hours a day to receive children in need. This can happen due to a 
        parent being arrested or a similar situation where the child needs a place to go immediately.`
    },
    {
        key: 't1p6',
        value: `Respite foster care is available for a few days at a time to give families a break from 
        stressful situations. This gives the primary foster family a short period of time to rest.`
    }
]


export const  tab2Content = [
    {
        key: 't2p1',
        value: `Sometimes through the foster care process, a foster caregiver will make the decision to adopt a child. 
        This can occur if the child has been emancipated from their birth parents – and therefore eligible to be adopted.`
    },
    {
        key: 't2p2',
        value: `Usually the children available for adoption from foster care are older, with the
            average age being around nine. A lot are teenagers or pre-teens, with almost 50% coming from 
            minorities. About 40% of these children are in sibling groups who must be adopted together to stay together.
            Often these children have physical or mental disabilities, or emotional problems resulting from abuse, 
            neglect, abandonment and the constant turmoil of an unstable life. All these children are looking for 
            the security of a permanent family situation.`
    },
    {
        key: 't2p3',
        value: `It can take between 9 months to a year to finalize the adoption. The length 
        of time depends on how long it takes to find a child, get the recommendations to adopt a child, 
        and completion of the supervision and visitation periods prior to finalization in court.`
    },
]

export const tab3Content = [
    {
        key: 't3p1',
        value: `In some instances, it may be easier for a member of a foster child's family to become 
        foster parents for that child, due to the provisions of The Fostering Connections Act. 
        This law allows states to waive non-safety-related foster care licensing standards on a case-by-case 
        basis for family members who are seeking to become foster parents.`
    }
]

export const tab4Content = [
    {
        key: 't4p1',
        value: `The bond between siblings may be the longest lasting relationship across the lifetime 
        of a child. Often, siblings who are placed together in foster care can experience higher rates of
         stability, a shorter length of time in foster care, and suffer less emotional trauma.`
    },
    {
        key: 't4p2',
        value: `Our goal is to place foster sibling groups together whenever possible to give foster 
        children every opportunity to excel in their foster family environment.`
    }
]

export const tab5Content = [
    {
        key: 't5p1',
        value: `Foster household is viewed as the primary treatment setting, which caters to the physical, 
        emotional and social needs of children and youths in a supportive family setting until the natural 
        family can be reunited or a permanent placement through adoption can be arranged.`
    }
]