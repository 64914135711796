import React from 'react'
import { Typography, Grid, Grow, Box,  Card, Slide, CardActionArea, CardContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleApiWrapper } from 'google-maps-react';
import GoogleMapsContainer from './GoogleMapsContainer';
import ImageSlider from '../../layout/ImageSlider';


const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      padding: '30px',
    },
    title: {
        padding: '0 16px'
    },
    body: {
        color: '#1A355D',
        padding: '20px',
    },
    panelContainer: {
        color: '#1A355D',
    },
    sectionBox: {
        color: '#1A355D',
        padding: '30px',
        boxShadow: '2px 2px 11px 1px rgb(90 78 80 / 55%)'
  
    },
    card: {
        padding: '20px',
        '& .MuiButtonBase-root': {
          cursor: 'default',
          backgroundColor: '#3567b20f'
        }
      },
    cardTitle: {
        color: '#d04e53',
        paddingBottom: '10px'
    },
    cardsItems:{
        padding: '20px'
      },
    head: {
    color: '#22477E'
    }
  }));

const  GoogleMapView= () =>{
    const classes = useStyles();
    return (
        <div className={classes.root}>
             <Box className={classes.sectionBox}>
                 <Grid container className={classes.panelContainer}>
                 <GoogleMapsContainer />             
                 
                    
                 </Grid>

             </Box>
        </div>
  )
}

export default GoogleMapView;