
import './App.css';
import 'react-notifications/lib/notifications.css';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, createTheme  } from '@material-ui/core/styles';
import { NotificationContainer } from 'react-notifications';
import HeaderMenu from './layout/HeaderMenu';
import Footer from './layout/Footer';
import ServicesView from './components/floorplan/floorplanView';
import HomeView from './components/home/HomeView';
import ContactUsView from './components/contactUs/ContactUsView';
import EventsView from './components/location/location';
import GoogleMapView from './components/GoogleMap/GoogleMapView';

const App = () => {
  const appTheme = createTheme({
    typography: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    }
  });
  
  appTheme.typography.h4 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1.675rem',
    fontWeight: '400',
    [appTheme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.7rem',
    },
    [appTheme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1.5rem',
    },
  };

  appTheme.typography.h6 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1.1rem',
    fontWeight: '500',
    [appTheme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      fontWeight: '500',
    }
  };

  appTheme.typography.subtitle1 = {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
    fontSize: '1rem',
    fontWeight: '400',
    [appTheme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
      fontWeight: '400',
    }
  };

  return (
    <ThemeProvider theme={appTheme}>
      <div className="App">
        <HeaderMenu />
        <div style={{minHeight: '1080px'}}>
          <Switch>
            <Route path="/" name="Home" exact component={HomeView}/>
            <Route path="/location" name="Location" exact component={GoogleMapView}/>
            <Route path="/services" name="Services" exact component={ServicesView}/>
            <Route path="/contactUs" name="Contact Us" exact component={ContactUsView}/>
            <Route path="/events" name="Events" exact component={EventsView}/>
          </Switch>
        </div>
        <Footer />
      </div>
      < NotificationContainer />
    </ThemeProvider>
  );
}

export default App;
